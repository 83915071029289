import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout/layout";
import * as styles from "./styles.module.css";
import "@fontsource/lato";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <div className={styles.content}>
          <h1>Contact</h1>
          <form
            name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className={styles.field}>
              <label className={styles.label} htmlFor={"name"}>
                Name
              </label>
              <div className={styles.control}>
                <input
                  className={styles.input}
                  type={"text"}
                  name={"name"}
                  onChange={this.handleChange}
                  id={"name"}
                  required={true}
                />
              </div>
            </div>
            <div className={styles.field}>
              <label className={styles.label} htmlFor={"email"}>
                Email
              </label>
              <div className={styles.control}>
                <input
                  className={styles.input}
                  type={"email"}
                  name={"email"}
                  onChange={this.handleChange}
                  id={"email"}
                  required={true}
                />
              </div>
            </div>
            <div className={styles.field}>
              <label className={styles.label} htmlFor="phone">
                Phone Number
              </label>

              <div className={styles.control}>
                <input
                  maxLength={"15"}
                  type={"tel"}
                  id={"phone"}
                  name={"phone"}
                  className={styles.input}
                  onChange={this.handleChange}
                  required={true}
                />
              </div>
            </div>
            <div className={styles.field}>
              <label className={styles.label} htmlFor={"message"}>
                Message
              </label>
              <div className={styles.control}>
                <textarea
                  className={styles.textarea}
                  name={"message"}
                  onChange={this.handleChange}
                  id={"message"}
                  required={true}
                />
              </div>
            </div>
            <div className={`${styles.field} ${styles.mobbtn}`}>
              <button
                className={`${styles.button} ${styles.is_link}`}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Layout>
    );
  }
}
